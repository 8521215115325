import { Stack } from '@mui/material';
import sponaGives from '../../../assets/img/public-pages/spona-gives.png';
import createProfile from '../../../assets/img/public-pages/create-profile.svg';
import publishCaseStudy from '../../../assets/img/public-pages/publish-case-study.svg';
import submitYourPitch from '../../../assets/img/public-pages/submit-your-pitch.svg';
import { primaryDark, secondaryOrange, secondaryYellow } from '../../../color';
import HeroImage from '../../../assets/img/public-pages/how-to-find-work-hero.png';
import ReusableHeroSection from '../../../components/public-screen-components/ReusableHeroSection';
import HowDoesItWork from '../../../components/public-screen-components/HowDoesItWork';
import SponaOptions from '../../../components/public-screen-components/SponaOptions';
import BecomeAPartOfSpona from '../../../components/public-screen-components/BecomeAPartOfSpona';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../components/seo/MetaTags';

const metaTags = {
  title: `Spona - Are you a Digital Agency looking for work? `,
  description: `Get your next big project with Spona. Register, publish a Case Study and Get your next big project now. Find out here how it all works.`,
};

const HowToFindWork = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <ReusableHeroSection
        title="Get Your Next Big Project"
        content="Focus on growing your business while Spona makes sure that exciting new business opportunities come your way. Projects listed on our platform are standardized and contain all the essential information you need to decide whether you've found the right opportunity for you, so you don't invest your time before the real work actually begins. Reach out to buyers of digital services across the world and build new connections with Spona."
        image={HeroImage}
        sx={{ height: { xs: `calc(100vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
        alt="Two colleagues reading notes from multi color post it notes."
      />

      <HowDoesItWork
        items={[
          {
            title: 'Create a profile',
            content:
              'To sign up for Spona, you need your company name and business email. After that, you will need to register your company details to create a full profile. You will be guided step-by-step through the entire process.',
            image: createProfile,
            color: secondaryYellow[100],
            alt: 'Ilustration_create a profile',
          },
          {
            title: 'Publish a case study',
            content:
              'Once your profile is verified, you will be able to browse all projects listed on the platform. To enable pitching, you need to post at least one case study and request a review from your previous client. This gives proof of credibility and valuable insight to your future buyers.',
            image: publishCaseStudy,
            color: secondaryYellow[100],
            alt: 'Illustration_Publish a case study',
          },
          {
            title: 'Submit your pitch',
            content:
              'Describe your company, your proposed strategy and attach relevant material. If your pitch is approved, you will proceed to the second round where you have the opportunity to chat with the buyer and schedule a meeting to discuss the project in more detail.',
            image: submitYourPitch,
            color: secondaryYellow[100],
            alt: 'Illustration_Submit your pitch',
          },
        ]}
      />

      <SponaOptions
        title="Spona Gives Your Business…"
        clickableOptionBorderLeftColor={secondaryOrange[501]}
        optionsProps={[
          {
            title: 'Reach',
            content:
              'Build your online presence by publishing content which showcases your products and know-how. Show your potential clients your expertise and promote your services ahead of time, giving you an increased chance of being selected for your next project.',
            image: sponaGives,
            alt: 'Personal computer showing statistic report on the screen',
          },
          {
            title: 'Credibility',
            content:
              'Connect with buyers through our platform and gain invaluable feedback and credibility through client-reviewed and scored project case studies. Our team will verify the content ourselves, creating a handy portfolio of thoroughly vetted work.  ',
            image: sponaGives,
            alt: 'Personal computer showing statistic report on the screen',
          },
          {
            title: 'Opportunities',
            content:
              'Browse projects and find out immediately what your potential clients are looking for. Show them that you’ve got exactly what they need by submitting a pitch with our user-friendly template.',
            image: sponaGives,
            alt: 'Personal computer showing statistic report on the screen',
          },
        ]}
      />

      <BecomeAPartOfSpona
        title="Get started on your project"
        bgcolor="secondaryYellow.100"
        color={primaryDark[900]}
      />
    </Stack>
  </>
);

export default HowToFindWork;
