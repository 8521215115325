/* eslint-disable import/no-cycle */
import Stack from '@mui/material/Stack';
import BuildYourProjects from './components/BuildYourProjects';
import EvolveYourBusiness from './components/EvolveYourBusiness';
import ProjectBenefits from './components/ProjectBenefits';
import HomepageHero from './components/HomepageHero';
import ForSellers from './components/ForSellers';
import Testimonials from '../../components/public-screen-components/Testimonials';
import WhyUs from './components/WhyUs';
import BecomeAPartOfSpona from '../../components/public-screen-components/BecomeAPartOfSpona';
import { testimonialsArray } from './utils';
import CompanyLogos from './components/CompanyLogos';
import MetaTags from '../../components/seo/MetaTags';

const metaTags = {
  title: `Spona - Streamline project collaboration, management, finance`,
  description: `Spona simplifies project collaboration, management, and financing, offering new clients extensive access and excellent support on a single platform.`,
};

const Homepage = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack width="100%" overflow="hidden">
      <HomepageHero />

      <CompanyLogos />

      <BuildYourProjects />

      <EvolveYourBusiness />

      <WhyUs />

      <ProjectBenefits />

      <Testimonials testimonials={testimonialsArray} />

      <ForSellers />

      <BecomeAPartOfSpona title="Join our community today" />
    </Stack>
  </>
);

export default Homepage;
